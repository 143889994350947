import { css } from '@emotion/react';
import { LoadingSpinner } from '@minted/minted-components';
import PropTypes from 'prop-types';
import {
  React, useState, useEffect,
} from 'react';

import { SITE_TYPES } from '../../core/constants';
import { DIGITAL_WEDDING_WEBSITE_PK } from '../../website/constants';
import { getDesign } from '../../website/utils';

const couplePhotoDefault = require('../../website/images/website/photo-couple.png');

const fitToParent = css`
  max-width: 100%;
  max-height: 100%;
`;

const grayscaleStyles = css`
  ${fitToParent}
  filter: grayscale(1);
  -webkit-filter: grayscale(1);
`;

const ImageWrapper = ({
  children, siteUrl,
}) => {
  if (siteUrl) {
    return (
      <a
        css={fitToParent}
        href={siteUrl}
      >
        {children}
      </a>
    );
  }

  return (
    <div
      css={fitToParent}
    >
      {children}
    </div>
  );
};

const SiteCardImage = ({
  sectionType, site, siteUrl,
}) => {
  const isWedding = site.siteType === SITE_TYPES.WEDDING;
  const [
    imageUrl,
    setImageUrl,
  ] = useState(site.savedDesignImage?.preview || site.previewUrl || '');
  const [
    isLoadingImage,
    setIsLoadingImage,
  ] = useState(false);

  useEffect(() => {
    if (isWedding && !Boolean(site.previewUrl)) {
      setIsLoadingImage(true);
      getDesign(site.mintedProductSku).then((results)=> {
        const colorways = results.products.find((product)=> product.kind.code === DIGITAL_WEDDING_WEBSITE_PK).colorways;
        const colorway = colorways.find((colorway) => colorway.externalId === site.mintedProductColorChoiceId);

        setImageUrl(colorway.images.pz);
      }).catch(() => {
        setImageUrl(couplePhotoDefault);
      }).finally(() => {
        setIsLoadingImage(false);
      });
    }
  }, [
    isWedding,
    site,
  ]);

  return (
    <ImageWrapper
      siteUrl={siteUrl}
    >
      <LoadingSpinner
        alignment="center"
        loading={isLoadingImage}
        variation="dark"
      />
      <img
        css={
          [
            'archived',
            'deleted',
          ].includes(sectionType)
            ? grayscaleStyles
            : fitToParent
        }
        data-cy="sitePreview"
        src={imageUrl}
      />
    </ImageWrapper>
  );
};

ImageWrapper.propTypes = {
  children: PropTypes.array.isRequired,
  siteUrl: PropTypes.string,
};

SiteCardImage.propTypes = {
  sectionType: PropTypes.string.isRequired,
  site: PropTypes.object.isRequired,
  siteUrl: PropTypes.string,
};

export default SiteCardImage;

import { css } from '@emotion/react';
import {
  Icon,
  IconButton, styleUtils, theme,
} from '@minted/minted-components';
import camelCase from 'lodash/camelCase';
import PropTypes from 'prop-types';
import React from 'react';

import NavigationLink from './NavigationLink';
import { navigationLinksPropType } from './utils';
import ViewWebsite from './ViewWebsite';
import Track from '../../core/instrumentation';

const {
  rem, transition,
} = styleUtils;

const propTypes = {
  forwardRef: PropTypes.object,
  isCollapsed: PropTypes.bool,
  navigationLinks: navigationLinksPropType,
};

const borderWidth = 1;
const navLinkBarHeight = 48;

const containerBorderWidthRem = rem(borderWidth);

const bottomNavContainerStyles = ({
  isCollapsed,
}) => css`
  ${transition('all')};
  background: ${theme.colors.gray100};
  border-bottom: ${containerBorderWidthRem} solid ${theme.colors.gray300};
  position: relative;
  top: 0;
  ${isCollapsed
  && `
      box-shadow: 0 ${rem(1)} ${rem(4)} 0 rgba(0, 0, 0, 0.25);
      margin-top: ${rem(-51)};
    `
}
`;

const hyperlinkContainerStyles = css`
  ${theme.typeStyles.bodyMediumStyles}
  align-items: center;
  cursor: pointer;
  display: block;
  height: 100%;
  padding-left: ${theme.spacing.x6};
  padding-right: ${theme.spacing.x6};
`;

const navFlexContainerStyles = css`
  align-items: center;
  display: inline-flex;
  height: 100%;
  position: relative;
  ${theme.typeStyles.captionMediumStyles};
  text-transform: uppercase;
`;

const navCenteredFlexContainerStyles = css`
  display: flex;
  justify-content: center;
`;

const navGridContainerStyles = css`
  display: grid;
  grid-template-columns: repeat(5, auto);
  height: ${rem(navLinkBarHeight)};
`;

const iconPositionStyles = css`
  ${theme.typeStyles.bodyBoldStyles};
  position: absolute;
  top: ${rem(12)};
  right: ${rem(24)};
`;

const hyperlinkSelectionStyles = ({
  isActive,
}) => css`
  background-color: ${isActive
    ? theme.colors.gray700
    : 'transparent'
};
  bottom: -${containerBorderWidthRem};
  height: ${rem(4)};
  position: absolute;
  width: 100%;
  .FullNavigation_navLink:hover & {
    background-color: ${theme.colors.gray200};
  }
`;

const FullNavigationBottomNav = ({
  forwardRef,
  isCollapsed,
  navigationLinks,
}) => (
  <div
    css={
      bottomNavContainerStyles({
        isCollapsed,
      })
    }
    ref={forwardRef}
  >
    <div
      css={navCenteredFlexContainerStyles}
    >
      <div
        css={navGridContainerStyles}
        data-cy="navigationMenu"
      >

        {
          navigationLinks
            .map(({
              name,
              routeName,
            }) => (
              <NavigationLink
                key={routeName}
                routeName={routeName}
              >
                {
                  ({
                    isActive,
                    onNavigate,
                  }) => (
                    <div className="FullNavigation_navLink">
                      <div
                        css={hyperlinkContainerStyles}
                        onClick={
                          () => {
                            Track.emit({
                              event: 'click_tabs',
                              value: routeName === 'guests.all' ? 'guests' : routeName,
                            });
                            onNavigate();
                          }
                        }
                      >
                        <div
                          css={navFlexContainerStyles}
                          data-cy={`${camelCase(name)}Tab`}
                        >
                          {name}
                          <div
                            css={
                              hyperlinkSelectionStyles({
                                isActive,
                              })
                            }
                          />
                        </div>
                      </div>
                    </div>
                  )
                }
              </NavigationLink>
            ))
        }

        {
          isCollapsed && (
            <ViewWebsite>
              {
                ({
                  navigateToWeddingWebsite,
                }) => (
                  <div
                    css={iconPositionStyles}
                    data-cy="viewYourWebsiteLink"
                  >
                    <IconButton
                      iconType={Icon.types['open-in-new']}
                      onClick={navigateToWeddingWebsite}
                      size={IconButton.sizes.large}
                      type={IconButton.types.secondary}
                    />
                  </div>
                )
              }
            </ViewWebsite>
          )
        }

      </div>
    </div>
  </div>
);

FullNavigationBottomNav.propTypes = propTypes;

export default FullNavigationBottomNav;

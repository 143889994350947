import { css } from '@emotion/react';
import { theme, styleUtils } from '@minted/minted-components';
import PropTypes from 'prop-types';
import React from 'react';

import SiteCard from './SiteCard';
import { SITE_TYPES } from '../../core/constants';

const blankStyles = css`
  ${theme.typeStyles.bodyExtraSmallStyles}
`;

const gridStyles = css`
  display: grid;
  grid-template-columns: repeat(2, 165px);
  grid-row-gap: ${theme.spacing.x6};
  grid-column-gap: ${theme.spacing.x8};

  ${theme.media.lessThan(theme.breakpoints.small)(css`
    grid-column-gap: ${styleUtils.rem(3)};
  `)}

  ${theme.media.greaterThan(theme.breakpoints.extraSmall)(css`
    grid-template-columns: repeat(2,180px);
  `)}

  ${theme.media.greaterThan(theme.breakpoints.medium)(css`
    grid-template-columns: repeat(3,180px);
  `)}

  ${theme.media.greaterThan(theme.breakpoints.large)(css`
    grid-template-columns: repeat(4,260px);
  `)}

  ${theme.media.greaterThan(theme.breakpoints.extraLarge)(css`
    grid-template-columns: repeat(4,300px);
  `)}
`;

const sectionStyles = css`
  padding-left: ${theme.spacing.x2};
  padding-right: ${theme.spacing.x2};
`;

const activeSectionHeaderStyles = css`
  ${theme.typeStyles.bodyExtraLargeStyles}
  padding-top: ${theme.spacing.x8};
  padding-bottom: ${theme.spacing.x4};
`;

const otherSectionHeaderStyles = css`
  ${theme.typeStyles.bodyExtraLargeStyles}
  padding-top: ${theme.spacing.x12};
  padding-bottom: ${theme.spacing.x4};
`;

const archiveNoteStyles = css`
  ${theme.typeStyles.bodySmallStyles}
  padding-bottom: ${theme.spacing.x6};
`;

const sectionTitles = {
  active: 'ACTIVE',
  archived: 'ARCHIVED',
  deleted: 'RECENTLY DELETED',
};

const sectionFilters = {
  active: (sites) => sites.filter((site) => (site.status === 'published')),
  archived: (sites) => sites.filter((site) => (site.status === 'archived')),
  deleted: (sites) => sites.filter((site) => site.status === 'deleted'),
};

const EmptySection = ({
  sectionType,
}) => (
  <div css={blankStyles}>
    {`You do not have any ${sectionType} sites right now.`}
  </div>
);

const SiteSection = ({
  sectionType, setErrorMessage, sites,
}) => {
  const filteredSites = sectionFilters[sectionType](sites);

  if (sectionType === 'deleted' && filteredSites.length === 0) {
    return null;
  }

  if (
    sectionType === 'archived' &&
    sites.every((site) => site.siteType === SITE_TYPES.WEDDING)
  ) {
    return null;
  }

  return (
    <div
      css={sectionStyles}
      data-cy={`${sectionType}SitesSection`}
    >
      <div css={sectionType === 'active' ? activeSectionHeaderStyles : otherSectionHeaderStyles}>
        {sectionTitles[sectionType]}
      </div>
      {
        sectionType === 'archived' &&
            (
              <div css={archiveNoteStyles}>
                Holiday Websites are archived 120 days after they're created.
              </div>
            )
      }
      <div
        css={gridStyles}
      >
        {
          filteredSites.map((site) => (
            <SiteCard
              key={site.id}
              sectionType={sectionType}
              setErrorMessage={setErrorMessage}
              site={site}
            />
          ))
        }
      </div>
      {filteredSites.length === 0 && <EmptySection sectionType={sectionType} />}
    </div>
  );
};

EmptySection.propTypes = {
  sectionType: PropTypes.string.isRequired,
};

SiteSection.propTypes = {
  sectionType: PropTypes.string.isRequired,
  setErrorMessage: PropTypes.func,
  sites: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default SiteSection;

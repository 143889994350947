import { css } from '@emotion/react';
import { useMediaQueryState } from '@minted/minted-components';
import PropTypes from 'prop-types';
import React, {
  Fragment,
  useRef,
} from 'react';

import FullNavigation from './FullNavigation';
import MobileNavigation from './MobileNavigation';
import SimplifiedMobileNavigation from './SimplifiedMobileNavigation';
import SimplifiedNavigation from './SimplifiedNavigation';
import { SITE_TYPES } from '../../core/constants';
import useElementProp from '../../core/hooks/useElementProp';
import useScrollY from '../../core/hooks/useScrollY';

const propTypes = {
  siteType: PropTypes.string,
};

const SiteNavigation = ({
  siteType,
}) => {
  const ref = useRef();

  const mediumMediaQueryState = useMediaQueryState({
    mediaQuerySize: 'medium',
  });
  const isMobile = mediumMediaQueryState === 'BELOW';

  const {
    isScrolledBelow,
  } = useScrollY(32);

  const elementHeight = (
    useElementProp({
      propName: 'offsetHeight',
      ref,
    })
  );

  const isSimplifiedSite = siteType === SITE_TYPES.HOLIDAY;
  let navigationLinks = [];

  if (siteType === SITE_TYPES.WEDDING) {
    navigationLinks = [
      {
        name: 'Dashboard',
        routeName: 'dashboard',
      },
      {
        name: 'Settings',
        routeName: 'settings',
      },
      {
        name: 'Designs',
        routeName: 'designs',
      },
      {
        name: 'Pages',
        routeName: 'pages',
      },
      {
        name: 'Guests',
        routeName: 'guests.all',
      },
    ];
  } else if (siteType === SITE_TYPES.ONLINE_INVITATION)  {
    navigationLinks = [
      {
        name: 'Settings',
        routeName: 'settings',
      },
      {
        name: 'Design Site',
        routeName: 'pages',
      },
      {
        name: 'Manage Guests',
        routeName: 'guests',
      },
    ];
  }

  return (
    <Fragment>
      <div
        css={
          css`
            height: ${elementHeight}px;
          `
        }
      />

      <div
        css={
          css`
            position: fixed;
            top: 0;
            width: 100%;
            z-index: 11;
          `
        }
        ref={ref}
      >
        {
          isMobile
            ? (
              !siteType || isSimplifiedSite
                ? (
                  <SimplifiedMobileNavigation
                    isMultiSiteDashboard={!siteType}
                  />
                )
                : (
                  <MobileNavigation
                    navigationLinks={navigationLinks}
                  />
                )
            )
            : (
              !siteType || isSimplifiedSite
                ? (
                  <SimplifiedNavigation
                    isMultiSiteDashboard={!siteType}
                  />
                )
                : (
                  <FullNavigation
                    isCollapsed={isScrolledBelow}
                    navigationLinks={navigationLinks}
                  />
                )
            )
        }
      </div>
    </Fragment>
  );
};

SiteNavigation.propTypes = propTypes;

export default SiteNavigation;

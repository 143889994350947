import { css } from '@emotion/react';
import {
  Button, Icon, IconButton, Popover, styleUtils, theme,
} from '@minted/minted-components';
import PropTypes from 'prop-types';
import React, { Fragment, useContext } from 'react';

import FullNavigationBottomNav from './FullNavigationBottomNav';
import MintedWeddingWebsitesLogo from './MintedWeddingWebsitesLogo';
import NavigationContext from './NavigationContext';
import { navigationLinksPropType } from './utils';
import ViewWebsite from './ViewWebsite';

const {
  rem, transition,
} = styleUtils;

const propTypes = {
  forwardRef: PropTypes.object,
  isCollapsed: PropTypes.bool,
  navigationLinks: navigationLinksPropType,
};

export const borderWidth = 1;
export const navLinkBarHeight = 48;

const navigationHeadingStyles = ({
  isCollapsed,
}) => css`
  ${transition('all')};
  align-items: center;
  background: ${theme.colors.gray100};
  border-bottom: ${rem(3)} solid ${theme.colors.gray300};
  display: flex;
  height: ${isCollapsed ? rem(48) : rem(64)};
  justify-content: space-between;
  padding-left: ${theme.spacing.x4};
  padding-right: ${theme.spacing.x4};
`;

const hyperlinkStyles = css`
  ${theme.typeStyles.bodySmallStyles}
  cursor: pointer;
  display: block;
  padding: ${theme.spacing.x3} ${theme.spacing.x6};
  text-decoration: none;
  width: ${rem(200)};
`;

const websiteLinkStyles = css`
  ${theme.typeStyles.bodyBoldStyles};
  display: flex;
  justify-content: flex-end;
  margin-left: auto;
  margin-right: ${theme.spacing.x6};
`;

const popoverPaddingStyles = css`
  padding-bottom: ${rem(15)};
  padding-top: ${rem(15)};
`;

const flexContainerStyles = css`
  align-items: center;
  display: flex;
`;

const iconButtonStyles = css`
  margin-right: ${theme.spacing.x2};
  margin-bottom: 2px;
`;

const navigateToMultiSiteDashboard = () => {
  window.location.assign('/dashboard');
};

const FullNavigation = ({
  forwardRef,
  isCollapsed,
  navigationLinks,
}) => {
  const {
    mintedUrls,
  } = useContext(NavigationContext);

  return (
    <Fragment>
      <div
        css={
          navigationHeadingStyles({
            isCollapsed,
          })
        }
      >
        <div css={flexContainerStyles}>
          <div
            css={iconButtonStyles}
            data-cy="headerBackButton"
          >
            <IconButton
              iconType={Icon.types['arrow-left']}
              onClick={navigateToMultiSiteDashboard}
              size={IconButton.sizes.large}
              type={IconButton.types.secondary}
            />
          </div>
          <MintedWeddingWebsitesLogo />
        </div>

        <ViewWebsite>
          {
            ({
              navigateToWeddingWebsite,
            }) => (
              <div
                css={websiteLinkStyles}
                data-cy="viewYourWebsiteLink"
              >
                <Button
                  iconAlignment={Button.iconAlignments.left}
                  iconType={Icon.types['open-in-new']}
                  onClick={navigateToWeddingWebsite}
                  text="View Your Website"
                  type={Button.types.tertiary}
                />
              </div>
            )
          }
        </ViewWebsite>

        <Popover.PopoverManager>
          {
            ({
              contentRef,
              isOpen,
              targetRef,
              togglePopover,
            }) => (
              <Fragment>
                <span
                  data-cy="accountMenu"
                  ref={targetRef}
                >
                  <IconButton
                    iconType={Icon.types.account}
                    onClick={togglePopover}
                    size={IconButton.sizes.large}
                    type={IconButton.types.secondary}
                  />
                </span>

                <Popover
                  open={isOpen}
                  ref={contentRef}
                >
                  <Popover.PopoverContent>
                    <div
                      css={popoverPaddingStyles}
                    >
                      <a
                        css={hyperlinkStyles}
                        data-cy="myAccountLink"
                        href={mintedUrls['my-account']}
                      >
                        My Account
                      </a>
                      <a
                        css={hyperlinkStyles}
                        data-cy="logoutLink"
                        href={mintedUrls.logout}
                      >
                        Logout
                      </a>
                    </div>
                  </Popover.PopoverContent>
                </Popover>
              </Fragment>
            )
          }
        </Popover.PopoverManager>
      </div>
      <FullNavigationBottomNav
        forwardRef={forwardRef}
        isCollapsed={isCollapsed}
        navigationLinks={navigationLinks}
      />
    </Fragment>
  );
};

FullNavigation.propTypes = propTypes;

export default FullNavigation;
